import React from 'react'
import Layout from '../../components/layout'
import Metatags from "../../components/Metatags"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
//import home page components
import GetStarted from '../../components/getstarted'
import Banner from '../../components/Banner';
import HomeIntegrationCompanies from '../../constants/Home/IntegrationCompanies';
import HomeCounter from '../../constants/Home/homeCounter';
import HomePopupFeatures from '../../constants/ExitIntent/HomePopupFeatures';
import HomePopupTriggerRules from '../../constants/ExitIntent/HomePopupTriggerRules';
import HomePopupClients from '../../constants/EmailPopups/HomePopupClients';
import SwiperSlider from '../../components/SwiperSlider';
import Bg from '../../images/background-1.png';
import SliderImg1 from '../../images/mike.png';
import SliderImg2 from '../../images/jockbrocas.png';
import SliderImg3 from '../../images/caseyjones.png';

const Title = () => (
    <><h1><span className="style-highlight">Exit-Intent Popups
        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg>
    </span> to Reduce Abandonment and Increase Sales</h1></>
);
const ClientsTitle = () => (
    <><h2>Integrations Made <span className="style-highlight">Extremely Simple
        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg>
    </span></h2></>
);

const Slides = [
    {
        content: `
            <div className="swiper-slide-contents">
                <div className="elementor-slide-heading">"Was Not a fan of Popups, Until Now!"</div>
                <div className="elementor-slide-description">What I like here is, there is no subscriber limit and their templates are amazing. It also has a dashboard to monitor conversions/campaigns for all my website/clients!<br/><br/>
                <b>Mike</b> - mrdzyn.com 
                </div>
            </div>`,
        img: SliderImg1
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">"I Ditched Optinmonster&#8203;"</div><div className="elementor-slide-description">This has been the answer to my needs. I have jumped all in and now that I have played with it, I am slowly moving all my sites over to Optinly.<br><br><b>JockBrocas</b> - jockbrocas.com</div>
            </div>`,
        img: SliderImg2
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">Love Optinly!</div><div className="elementor-slide-description">One word pretty well sums it up - Awesome!<br><br>
            <b>Casey Jones</b> - www.cjco.com.au</div></div>`,
        img: SliderImg3
    },
]

const SwiperSlides = () => (
    <div className="position-relative">
        <SwiperSlider slidesPerView={1} slides={Slides} spaceBetween={0} slideDeley={5000} />
    </div>
);

const SpinTheWheelPopups = () => (
    <Layout className="landing-page">
        <Metatags
            title="Exit-Intent Pop-ups | Optinly"
            description="Increase lead generation with the exit-intent pop-ups. Optinly is a drag-and-drop pop-up builder that increases user engagement and conversion rates."
            keywords={[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url="https://www.optinly.com"
            pathname="/"
        />
        <Banner title={<Title />} description="Create exit intent popups for your business/eCommerce store on the go. Use Optinly’s free exit intent technology to display popups when users leave your website. Stop losing potential customers today!" actionUrl="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" actionText={"Create Your First Exit Popup"} slider={<SwiperSlides />} bgImg={Bg} />

        <HomeIntegrationCompanies />
        <HomeCounter />
        <HomePopupFeatures />
        <HomePopupTriggerRules />
        <HomePopupClients title={<ClientsTitle />} description="Integrately? Sendfox? Pabbly? We’ve got it all for you. Integrate Optinly exit-intent tool with your favorite email marketing service provider. Also, connect it with webhooks to do more at once." />
        <GetStarted title="Stop Abandonment and Boost Sales & Signups With Exit Popups" />
        <div className="py-5 faq-section">
            <div className="container py-3">
                <div className="mb-5 text-center hero-content wow fadeIn">
                    <h2>Exit Intent Popups <span className="style-highlight"> FAQ
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span>
                    </h2>
                </div>
                <Accordion allowZeroExpanded>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            What is an exit intent popup? How does it work?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>The exit intent technology allows you to display popups when users tend to leave your website. The logic behind is exit-intent popups is it works based on the cursor movement. When the user tries to reach the close button or moves the cursor towards the top of the screen, the script automatically detects it and displays the popup on the screen. So, this is how exit popups work.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Are Exit Intent Popups Effective?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Of course yes. Exit intent popups are a great tool to prevent visitors from abandoning your website/shopping carts. Stats say that using exit popups on your website increases conversions from 5-10% while certain websites claim they were able to witness conversions 300%.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Will Users Be Annoyed If They See Popups When Leaving Site?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Exit intent popups are like your last-minute window opportunity. The user has already decided to leave your website. So displaying a popup at the last minute isn’t going to be much of an issue. But on the positive side, if your exit popup campaign is able to drive attention and the offers are lucrative, there’s going to be conversions.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            What are some of the best exit-intent popup examples around?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Websites and eCommerce stores around the world have started leveraging exit popups to a great extent, thanks to the best exit popup software around. <a href="https://optinly.com/blog/20-best-exit-intent-popup-examples/"> Take a look at some of the best exit intent popups around.</a></p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            How to Create Highly Converting Exit Intent Popups?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>When it comes to displaying exit intent popups, you’ve got to make sure they are powerful. And in order to be so, you need to make sure you follow the best practices right from the start. <a href="https://optinly.com/blog/10-best-exit-intent-popup-hacks-to-grow-email-list/">Here are a few hacks to help create highly converting exit intent popups</a></p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Can I Display Exit Intent Popups for Mobiles and Tablets?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Yes. With Optinly, you can display exit popups for mobile devices and tablets. Optinly’s exit-intent script displays exit popups based on user scrolling and tab minimizing/closing actions.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Is Optinly a free exit-intent tool free to use?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Yes, Optinly is a free exit intent tool that supports both WordPress and custom websites. You get more powerful features when you opt for the Growth Plan.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>

    </Layout>
);

export default SpinTheWheelPopups;