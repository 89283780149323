import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { FaArrowRight } from 'react-icons/fa';

import FeatureIcon1 from '../../images/features/email-popups/Create-new-campaign.png'
import FeatureIcon2 from '../../images/features/email-popups/choose-templates-1.png'
import FeatureIcon3 from '../../images/features/email-popups/customization.png'
import FeatureIcon4 from '../../images/features/email-popups/rules-1.png'

class HomePopupFeatures extends Component {
    render() {
        return (
            <div className="home-popup-features-section">
                <div className="container">
                    <Row className="align-items-center text-center py-3">
                        <Col md="12">
                            <div className="hero-content wow fadeIn">
                                <h2>Start Creating Exit Popup Campaigns in <span className="style-highlight"> 4 Simple Steps 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon1} alt="" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4 ps-5 pe-3">
                                <h3>Create New Campaign</h3>
                                <p>Working with Optinly’s exit popup software is always a breeze. Start off by creating a new campaign. Choose an appropriate growth marketing goal, set a custom popup campaign name and proceed.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4 ps-3 pe-5">
                                <h3>Choose Exit Popup Template</h3>
                                <p>Optinly comes with 100+ highly responsive, customizable popup templates. Choose what you think might be an ideal fit for your exit popup campaign and proceed.</p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon2} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon3} alt="" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4 ps-5 pe-3">
                                <h3>Customize Exit Popup Template</h3>
                                <p>Customize your exit popup template to a high level using Optinly’s built-in popup editor. Update your changes and head back to the display rules dashboard.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center pt-5">
                        <Col md="6">
                            <div className="mb-4 ps-3 pe-5">
                                <h3>Set Display Rules and Go Live</h3>
                                <p>Choose exit intent technology option for your campaign. Set the retargeting period if necessary. Update changes and see your first exit intent popup campaign going live. Usually, it’s easier than said!</p>
                                <p>
                                    <a className="btn btn-secondary mt-3" href="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" target="_blank">
                                        Create Exit Popup Now <FaArrowRight />
                                    </a>
                                </p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon4} alt="" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default HomePopupFeatures
