import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { FaCheckSquare } from 'react-icons/fa';

import FeatureIcon1 from '../../images/features/exit-intent/Template.png'
import FeatureIcon2 from '../../images/features/exit-intent/popup-editor-1.png'
import FeatureIcon3 from '../../images/features/exit-intent/board.png'

class HomePopupTriggers extends Component {
    render() {
        return (
            <>
                <div className="home-multi-popup-trigger-features-section">
                    <div className="container">
                        <Row className="align-items-center text-center pt-3">
                            <Col md="12">
                                <div className="hero-content wow fadeIn">
                                    <h2>What Makes Optinly the Best <span className="style-highlight"> Exit Intent Popup
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span> Tool?</h2>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4 ps-3 pe-5">
                                    <h3>Growth Based Marketing Scenarios</h3>
                                    <p>Optinly follows a goal-based popup marketing strategy where you choose from the 10 most common growth scenarios. Backed by appropriate popup templates, you can create popup campaigns in under minutes. Here are a few use case scenarios as to how you can use exit popups for your business growth.</p>
                                    <ul className="p-0">
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>Distribute lead magnets using exit intent triggers and capture leads simultaneously.</span></li>
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>Display coupon popups with exit triggers and increase cart value.</span></li>
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>Use promotional popups when visitors tend to leave the website and reduce abandonment.</span></li>
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>Take users to feedback forms using exit triggers - understand why they’re abandoning your webpage.</span></li>
                                    </ul>
                                    <p>There’s a lot more you can do with exit intent triggers. All you have to do is, find an appropriate scenario where you can leverage exit intent technology.</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon1} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
                <div className="home-multi-popup-trigger-features-section">
                    <div className="container">
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon2} alt="" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4 ps-5 pe-3">
                                    <h3>Powerful Built-in Exit Popup Editor</h3>
                                    <p>Make your popups look better and more appealing. Customization is the key to help brands connect popups with the web audience. Tweak your exit popup template with Optinly’s built-in exit popup editor extensively.</p>
                                    <ul className="p-0">
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>Customize popup text copy including font size, style, family and color. Add hyperlinks wherever necessary.</span></li>
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>Customize Call-to-action buttons. Redirect users to specific landing pages.</span></li>
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>Replace images on popup templates. Upload from local storage or choose from 1 million images in Pixabay.</span></li>
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>Check popup responsiveness. Toggle between desktop/tablet/mobile view to see popup appearance on multiple devices.</span></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center pt-5">
                            <Col md="6">
                                <div className="mb-4 ps-3 pe-5">
                                    <h3>In-depth Analytics Dashboard</h3>
                                    <p>Stay in touch with your exit popup campaign. Know how your exit popups are performing by taking a look at the campaign metrics. The user-friendly dashboard has everything you need and is totally clutter-free.</p>
                                    <ul className="p-0">
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>View the total number of popup campaigns created along with the number of visitors, clicks and responses.</span></li>
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>Gather individual campaign data - number of views, clicks, responses, type of popup and campaign creation date.</span></li>
                                        <li className="mb-1 d-flex align-items-center "><span><FaCheckSquare size="20" className="text-primary me-3" /></span> <span>Manage campaigns seamlessly - create/edit/delete campaigns on the go.</span></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon3} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
            </>
        )
    }
}

export default HomePopupTriggers
